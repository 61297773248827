//DEPENDENCIAS...
/*
+------------------------------------------------------------------------------
|  estilos CSS
+------------------------------------------------------------------------------
*/
import "@/assets/css/estilos.css"

/*
+------------------------------------------------------------------------------
|  vue - vuex - store - router
+------------------------------------------------------------------------------
*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/*
+------------------------------------------------------------------------------
|  local storage
+------------------------------------------------------------------------------
*/
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

/*
+------------------------------------------------------------------------------
|  Estadísticas VueChartkick
+------------------------------------------------------------------------------
*/
import { Chart } from 'chart.js'
import Chartkick from 'vue-chartkick'
Vue.use(Chartkick.use(Chart));

/*------------------------------------------------------------------------------
  bootstrap
-------------------------------------------------------------------------------*/
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { PopoverPlugin } from 'bootstrap-vue'
import { TooltipPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons) 
Vue.use(PopoverPlugin)
Vue.use(TooltipPlugin)

/*
+------------------------------------------------------------------------------
|  axios
+------------------------------------------------------------------------------
*/
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
const baseURL = store.state.baseURL;
axios.defaults.baseURL = baseURL;


/*
+------------------------------------------------------------------------------
|  Instancia de Vue
+-------------------------------------------------------------------------------
*/
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
