<template>
  <div id="app">
    <div class="container-fluid background-body"></div>
    <div class="col-11 mx-auto container-web">
      <div class="row h-100">
        <div id="content-body" class="col-12 flex-d-c">
          <div class="header-col row">
            <!-- cabecera -->
            <cabecera />
          </div>
          <div class="row flex">
            <div class="col-12">
              <div id="main">
                <!-- cuerpo contenido -->
                <div class="text-center" v-if="loading">
                  <b-spinner variant="primary" label="Text Centered"></b-spinner>
                </div>
                <div id="title" style="display: none;">
                  <b-icon icon="play-fill"></b-icon><span class="message">{{ message }}</span>
                </div>
                <transition name="fade">
                  <router-view/>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- pie -->
    <pie />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import cabecera from './components/Cabecera';
  import pie from './components/Pie';
  export default {
    name: 'App',
    data() {
      return {
      }
    },
    components: { cabecera, pie },
    computed: {
      ...mapState('auth',['usrLogin']),
      ...mapState(['loading', 'message']),
    },
  }
</script>
