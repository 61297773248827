<template>
  <div class="text-center">
    <p class="pie mt-5">Programa Kit Digital cofinanciado por los fondos Next Generation (EU) del Mecanismo de Recuperación y Resilencia.</p>
    <img class="mb-5" src="img/kit_digital.png" width="1000px">
    <footer>
      <div class="m-2">
        <span>Gestión Fisio Sport ISKANDRIA. Versión {{ version }}. Copyright &copy; {{ anyo }} SiiNet.com (SIINTERNET, SL)</span>
        <span class="ml-5">
          <b-icon icon="telephone-outbound-fill" class="mr-1" style="color:#008ca6;" />
           914 998 857
        </span> 
        <span class="enlace ml-5" @click="abrirPdf()">
          <b-icon icon="file-earmark-fill" class="mr-1" style="color:#008ca6;" />
          Datasheet (hoja de producto)
        </span>
      </div>
      <div class="pie">ISKANDRIA Fisio Sport, SL. | Avda. Pilar Miró, 61, 28521 Rivas Vaciamadrid (Madrid)
      </div>
    </footer>  
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['version']),

    anyo(){
      let today = new Date();
      let year = today.getFullYear();
      return year; 
    }
  }, 

  methods: {
    abrirPdf(){
      window.open(`https://gestion.fisioterapia-rivas.com/datasheet.pdf`, '_black');
    },
  }
}
</script>
<style>
.enlace {
  cursor: pointer;
}
.enlace:hover {
  color: #008ca6;
}
.pie {
  font-size: .8rem;
  margin-bottom: 40px;
}

</style>